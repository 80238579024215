import { Injectable } from '@angular/core';
import { DocumentNode } from '@apollo/client/core';
import * as Get from '@npm-libs/ng-getx';
import { GqlInput, GqlQueryType, IListState, RcgListItem, initialListState, normalizeGqlInputQuery } from '@rcg/core/models';
import { GqlResolverService } from '@rcg/core/services';
import { GraphqlClientService } from '@rcg/graphql';
import { IPaging } from '@rcg/standalone/models/paging.model';
import { deepDistinctUntilChanged } from '@rcg/standalone/rxjs-operators/deep-distinct-until-changed';
import { isNonNullable } from '@rcg/utils/type.utils';
import {
  Observable,
  catchError,
  combineLatest,
  combineLatestWith,
  concat,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  merge,
  of,
  pairwise,
  startWith,
  switchMap,
  take,
} from 'rxjs';
import { FilterExpressions } from '../models/filter-expressions';

@Get.NgAutoDispose
@Injectable()
export class InfinityListService {
  // Selectors
  readonly listStateR = initialListState.obs();

  readonly dataR = this.listStateR.pipe(
    map((state) => {
      return state.data;
    }),
    distinctUntilChanged((a, b) => a === b),
  );

  readonly dataCountR = this.listStateR.pipe(
    map((state) => {
      if (state.loading === true || !!state.error) {
        return [];
      }
      return state.data;
    }),
    distinctUntilChanged((a, b) => a === b),
    map((data) => data.length),
  );

  readonly allCountR = this.listStateR.pipe(
    map((state) => {
      if (state.loading === true || !!state.error) {
        return null;
      }
      return state.count ?? null;
    }),
    distinctUntilChanged((a, b) => a === b),
    map((count) => count),
  );

  private readonly refreshR = new Get.Rx<null>(null);

  private readonly gqlInputR = new Get.Rx<GqlInput<RcgListItem> | null | undefined>(null);

  private readonly countGqlInputR = new Get.Rx<GqlInput | null | undefined>(null);

  private readonly pagingR = new Get.Rx<IPaging | null>(null);

  private readonly searchR = new Get.Rx<string>('', [map<string, string>((s) => s.trim()), distinctUntilChanged<string>()]);

  private readonly expandedIdsR = new Get.Rx<number[]>([]);

  private readonly defaultPageLimit = 20;

  private readonly nonNullGqlInput = this.gqlInputR.value$.pipe(filter(isNonNullable));

  private readonly varResolvedGqlInput$ = this.nonNullGqlInput.pipe(switchMap((gi) => this.resolveGqlVariables(gi)));

  private currentGqlInput: GqlInput<RcgListItem> | undefined;

  private readonly varResolvedGqlInputWithChanges$ = merge(
    this.varResolvedGqlInput$,
    this.listStateR.value$.pipe(
      switchMap(() => this.varResolvedGqlInput$.pipe(take(1))),
      delay(1),
    ),
  ).pipe(
    startWith(null),
    pairwise(),
    map(([p, c]) => [c!, (p && JSON.stringify(p) !== JSON.stringify(c)) ?? false] as const),
    deepDistinctUntilChanged(),
  );

  private readonly varResolvedCountGqlInput$ = this.countGqlInputR.value$.pipe(
    switchMap((gi) => (gi ? this.resolveGqlVariables(gi) : of(null))),
  );

  private readonly countR = combineLatest([this.searchR.value$, this.varResolvedCountGqlInput$, this.refreshR.value$])
    .pipe(
      debounceTime(1),
      map(([search, countGqlInput]) => (countGqlInput ? this.mapGqlInputVariables(countGqlInput, search) : null)),
      switchMap((countGqlInput) => {
        return countGqlInput
          ? this.getGqlData<number>(
              normalizeGqlInputQuery(countGqlInput.query),
              countGqlInput.type,
              countGqlInput.variables,
              countGqlInput.responsePath,
            ).pipe(startWith(null))
          : of(null);
      }),
    )
    .obs();

  private readonly initPaging$ = this.pagingR.value$.pipe(
    debounceTime(1),
    switchMap((paging) => {
      if (paging) return of(paging);

      return this.nonNullGqlInput.pipe(
        map(({ limit }) => limit),
        distinctUntilChanged(),
        map((limit) => this.getInitialPaging(limit)),
      );
    }),
  );

  constructor(private graphQlClient: GraphqlClientService, private gqlResolver: GqlResolverService) {
    // subscribe to changes and load list data
    this.listStateR.subscribeTo(
      combineLatest([this.searchR.value$, this.initPaging$, this.varResolvedGqlInputWithChanges$, this.refreshR.value$])
        .pipe(
          debounceTime(1),
          switchMap(([search, paging, [gqlInput, hasGqlChanged]]) => {
            const state = this.listStateR.value;

            const limit = state.data.length ? paging.limit : paging.pageLimit;
            const newGqlInput = this.mapGqlInputVariables(gqlInput, search, limit);

            const loadingState = {
              ...state,
              error: null,
              loading: true,
              data: hasGqlChanged ? [] : state.data,
              search: search,
              count: null,
              gqlInput: newGqlInput,
            } as IListState;

            const newState$: Observable<IListState> = this.getGqlData<RcgListItem[]>(
              normalizeGqlInputQuery(newGqlInput.query),
              newGqlInput.type,
              newGqlInput.variables,
              newGqlInput.responsePath,
            ).pipe(
              map((data) => data ?? []),
              map((data) => {
                const hasMore = data.length > 0 && data.length % paging.pageLimit === 0;
                return {
                  ...state,
                  initial: false,
                  loading: false,
                  data,
                  paging: {
                    ...paging,
                    hasMore,
                    limit,
                  },
                } as IListState;
              }),
            );

            this.expandedIdsR.data = [];
            const dataMappedState$ = gqlInput.dataMapper
              ? newState$.pipe(
                  combineLatestWith(this.expandedIdsR.value$),
                  map(([state, expandedIds]) => {
                    const mappedData = gqlInput.dataMapper!(state.data, expandedIds);

                    if (
                      paging.hasMore &&
                      mappedData.length < state.data.length &&
                      state.data.length % paging.pageLimit === 0 &&
                      mappedData.length < paging.pageLimit
                    ) {
                      this.loadMore({
                        ...paging,
                        limit: paging.limit + paging.pageLimit,
                      });
                    }

                    return { ...state, data: mappedData };
                  }),
                )
              : newState$;

            return concat(of(loadingState), dataMappedState$).pipe(
              catchError((error) => {
                console.error('Infinity list data error-1', error);
                return of({
                  ...this.listStateR.value,
                  contacts: [],
                  loading: false,
                  error: error?.toString() ?? 'Napaka pridobivanja podatkov',
                }) as Observable<IListState>;
              }),
            );
          }),
          switchMap((state) =>
            this.countR.value$.pipe(
              map((count) => {
                state.count = count;
                return state;
              }),
            ),
          ),
        )
        .pipe(
          catchError((error) => {
            console.error('Infinity list data error-3', error);
            return of({
              ...this.listStateR.value,
              contacts: [],
              loading: false,
              error: error?.toString() ?? 'Napaka pridobivanja podatkov',
            }) as Observable<IListState>;
          }),
        ),
    );
  }

  loadMore(paging: IPaging) {
    if (!paging || !paging.hasMore) {
      return;
    }
    this.pagingR.data = paging;
  }

  search(search: string) {
    this.searchR.data = search;
  }

  refresh() {
    this.refreshR.data = null;
  }

  loadData(
    gqlInput: GqlInput<RcgListItem>,
    countGqlInput?: GqlInput,
    filters?: FilterExpressions | undefined | null,
    refreshOnSameGqlInput = false,
  ) {
    if (refreshOnSameGqlInput) {
      if (gqlInput && this.currentGqlInput && JSON.stringify(this.currentGqlInput) === JSON.stringify(gqlInput)) {
        this.refresh();
        return;
      }
      this.currentGqlInput = gqlInput;
    }

    this.gqlInputR.data = { ...gqlInput, filters: filters };

    this.countGqlInputR.data = countGqlInput
      ? { ...countGqlInput, filters: filters ? { ...filters, orderByExpressions: undefined } : undefined }
      : undefined;
  }

  clearData() {
    this.listStateR.value.data = [];
  }

  private getInitialPaging(limit: number | undefined): IPaging {
    return {
      limit: limit ?? this.defaultPageLimit,
      offset: 0,
      hasMore: true,
      pageLimit: limit ?? this.defaultPageLimit,
    };
  }

  private async resolveGqlVariables<D = unknown>(gqlInput: GqlInput<D>): Promise<GqlInput<D>> {
    let newGqlInput: GqlInput<D> | undefined;
    try {
      newGqlInput = await this.gqlResolver.resolveGqlInputVariables(gqlInput);
    } catch (error) {
      throw new Error(
        `Napaka pri razreševanju spremenljivk za GQL. \n Error: ${(error as Error | undefined)?.message ?? error?.toString()}`,
      );
    }

    if (!newGqlInput) throw new Error(`Napaka pri razreševanju spremenljivk za GQL. \n Resolved gqlInput is null`);
    return newGqlInput;
  }

  private mapGqlInputVariables<D = unknown>(gqlInput: GqlInput<D>, search: string, limit?: number): GqlInput<D> {
    let variables: Record<string, unknown> = {
      ...gqlInput.variables,
      ...(limit ? { limit } : {}),
    };

    if (gqlInput.searchable === true) {
      variables = {
        ...variables,
        search: `${gqlInput.search?.prefix ?? ''}${search?.trim() ?? ''}${gqlInput.search?.suffix ?? ''}`,
      };
    }
    return { ...gqlInput, variables };
  }

  private getGqlData<T>(
    gqlQuery: DocumentNode,
    gqlType: GqlQueryType,
    variables: Record<string, unknown> | null | undefined,
    responsePath: string | undefined | null,
  ): Observable<T | null> {
    if (!gqlQuery || !gqlType) return of(null);

    const gqlFn = gqlType === 'query' ? this.graphQlClient.query : this.graphQlClient.subscribe;
    const callableGqlFn = <T>(...args: Parameters<typeof gqlFn<T>>) => {
      const f = gqlFn<T>;
      return f.call(this.graphQlClient, ...args);
    };

    return callableGqlFn<{ data?: T }>({
      query: gqlQuery,
      variables: variables ?? {},
    }).pipe(
      map((result) => {
        if (!result?.data) return [];
        return responsePath ? this.gqlResolver.resolveGqlResponse(result.data, responsePath) : result!.data;
      }),
    );
  }

  public get expandedIds() {
    return this.expandedIdsR.value;
  }

  public setExpandedIds(expandedIds: number[]) {
    this.expandedIdsR.data = expandedIds;
  }
}
