import { Observable } from 'rxjs';

export interface Attachment {
  id: number;
  content_type?: string;
  file_name?: string;
  file_size?: number;
  inline?: boolean;
}

export const ATTACHMENT_BLOB_URL_LOADING: unique symbol = Symbol();
export type AttachmentBlobUrl = typeof ATTACHMENT_BLOB_URL_LOADING | string | undefined;

export interface AttachmentBlobUrls {
  [key: `${number}/${string}` | number]: Observable<AttachmentBlobUrl>;
}
