export const cidRegex: RegExp = /"(?:cid|rcgCID):([^"]*)"/g;
export const blobCidRegex: RegExp = /"blob:(?:http|https):\/\/[A-Za-z0-9.:/-]+#cid=(.+?)"/g;
export const blobRcgCidRegex: RegExp = /"blob:(?:http|https):\/\/[A-Za-z0-9.:/-]+#rcgCID=(.+?)"/g;

export const aidRegex: RegExp = /"(?:aid):([^"]*)"/g;
export const blobAidRegex: RegExp = /"blob:(?:http|https):\/\/[A-Za-z0-9.:/-]+#aid=(.+?)"/g;

export const aCIDRegex: RegExp = /"(?:cid:rcgatt)([^"]*)"/g;
export const blobAttachmentCidRegex: RegExp = /"blob:(?:http|https):\/\/[A-Za-z0-9.:/-]+#aCID=(.+?)"/g;

export const hexRegex: RegExp = /^[0-9A-Fa-f]+$/;
