import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { GraphqlClientService } from '@rcg/graphql';
import { firstValueFrom, map, of, retry, shareReplay, switchMap } from 'rxjs';
import { AttachmentsService } from './attachments.service';

const userAttachmentsSubscription = gql`
  subscription UserAttachments($userId: bigint!, $type: String!) {
    data: identity_user_attachments(where: { _and: [{ user_id: { _eq: $userId } }, { type: { _eq: $type } }] }) {
      attachment_id
    }
  }
`;

const setUserAttachmentMutation = gql`
  mutation SetUserAttachment($type: String!, $attachmentId: bigint!) {
    data: insert_identity_user_attachments_one(
      object: { type: $type, attachment_id: $attachmentId }
      on_conflict: { constraint: user_attachments_user_id_type_key, update_columns: [attachment_id] }
    ) {
      id
    }
  }
`;

const deleteUserAttachmentMutation = gql`
  mutation SetUserAttachment($type: String!) {
    data: delete_identity_user_attachments(where: { type: { _eq: $type } }) {
      affected_rows
    }
  }
`;

export type UserAttachmentType = 'picture';

@Injectable({
  providedIn: 'root',
})
export class UserAttachmentsService {
  constructor(private gql: GraphqlClientService, private attachment: AttachmentsService) {}

  private attachmentId$(userId: number, type: UserAttachmentType) {
    if (!userId) return of(undefined);

    return this.gql
      .subscribe<{ data: [{ attachment_id: number }] | [] }>({
        query: userAttachmentsSubscription,
        variables: {
          userId,
          type,
        },
      })
      .pipe(
        map(({ data }) => data[0]?.attachment_id),
        retry({ delay: 5000 }),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  public pictureAttachmentId$(userId: number) {
    return this.attachmentId$(userId, 'picture');
  }

  public pictureThumbnailUrl$(userId: number) {
    return this.pictureAttachmentId$(userId).pipe(
      switchMap((attachmentId) => {
        if (!attachmentId) return of(undefined);
        return this.attachment.getAttachmentBlobUrlById(attachmentId, true);
      }),
      retry({ delay: 5000 }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  public async setUserAttachment(type: UserAttachmentType, attachmentId: number) {
    await firstValueFrom(
      this.gql.mutate({
        mutation: setUserAttachmentMutation,
        variables: {
          type,
          attachmentId,
        },
      }),
    );
  }

  public async deleteUserAttachment(type: UserAttachmentType) {
    await firstValueFrom(
      this.gql.mutate({
        mutation: deleteUserAttachmentMutation,
        variables: {
          type,
        },
      }),
    );
  }
}
