import {
  ActionReducerMap,
  createFeatureSelector,
  MetaReducer,
} from '@ngrx/store';
import { IApplicationState } from '../models/application-state.model';
import * as fromRouter from '@ngrx/router-store';
import { IRouterState } from '../models/router.model';

export const applicationReducers: ActionReducerMap<IApplicationState> = {
  routerState: fromRouter.routerReducer,
};
export const metaReducers: MetaReducer<IApplicationState>[] = [];

export const selectCoreRouterState$ =
  createFeatureSelector<fromRouter.RouterReducerState<IRouterState>>(
    'routerState'
  );