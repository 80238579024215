import { FormGroup } from '@angular/forms';
import { FieldType } from '@ngx-formly/material';
import { RcgFormControl } from './form-control.model';
import { RcgFormlyFieldConfig } from './formly-field-config.model';
import { RcgFormlyFieldProps } from './formly-field-props.model';

export abstract class RcgFieldType<
  Value = unknown,
  Props extends RcgFormlyFieldProps<object, object> = RcgFormlyFieldProps,
> extends FieldType<RcgFormlyFieldConfig<Props, Value>> {
  override get key(): string {
    return super.key as string;
  }

  override get value(): Value {
    return this.formControl.value;
  }

  override set value(value: Value) {
    this.formControl.setValue(value);
  }

  override get form(): FormGroup<Record<string, RcgFormControl>> {
    return super.form as FormGroup<Record<string, RcgFormControl>>;
  }
}
