import { gql } from '@apollo/client/core';

export const userAppModules = gql`
  subscription userAppModulesSubscription($tenantId: bigint!, $userId: Int!) {
    data: identity_get_user_modules(
      args: { p_tenant_id: $tenantId, p_user_id: $userId }
      where: { module: { _and: [{ disabled: { _eq: false } }, { web_active: { _eq: true } }] } }
      order_by: { module: { name: asc } }
    ) {
      module {
        id
        name
        comment
        name_translation_name
        comment_translation_name
        material_icon_name
        parent_id
        web_active
      }
    }
  }
`;
