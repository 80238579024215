import { Injectable, OnDestroy } from '@angular/core';
import { MessagePayload, Messaging, getToken, isSupported, onMessage } from '@angular/fire/messaging';
import { Router } from '@angular/router';
import { AuthService, AuthState } from '@rcg/auth';
import { GraphqlClientService } from '@rcg/graphql';
import { ReplaySubject, combineLatest, firstValueFrom, pairwise } from 'rxjs';
import { retry, startWith } from 'rxjs/operators';
import { deleteDeviceMutation, insertDeviceMutation, setDeviceInfoMutation } from '../gql/fcm.gql';
import { NotificationComponent } from '../modules/notification/components/notification/notification.component';

@Injectable({
  providedIn: 'root',
})
export class FcmService implements OnDestroy {
  private fcmToken = new ReplaySubject<string | null>();

  private fcmSwChannel = new BroadcastChannel('FCM-serviceworker');

  private notificationComponent?: NotificationComponent;

  private hasRegistered = false;
  private appVersion!: string;

  constructor(
    private authService: AuthService,
    private messaging: Messaging,
    private router: Router,
    private gqlClient: GraphqlClientService,
  ) {}

  ngOnDestroy(): void {
    this.fcmSwChannel.close();
  }

  async registerFCM(notificationComponent: NotificationComponent, appVersion: string) {
    this.notificationComponent = notificationComponent;
    this.appVersion = appVersion;

    if (this.hasRegistered) return;
    this.hasRegistered = true;

    if (!(await isSupported())) {
      console.info('Skipping FCM registration. (not supported)');
      return;
    }

    onMessage(this.messaging, (message) => this.handleFcmMessage(message, true));

    combineLatest([this.authService.loggedInAuthState$, this.fcmToken.pipe(startWith(null), pairwise())]).subscribe(([loggedIn, token]) =>
      this.onFCMToken(loggedIn, ...token),
    );

    getToken(this.messaging).then(
      (data) => this.fcmToken.next(data),
      (error) => {
        if (`${error}`.includes('The notification permission was not granted')) return;

        console.warn('FCM token error', error);
      },
    );

    this.fcmSwChannel.onmessage = (event) => {
      if (!event.data) return;

      switch (event.data.type) {
        case 'FCM_BG_MESSAGES':
          if (!event.data.messages || !Array.isArray(event.data.messages)) break;

          for (const message of event.data.messages) {
            try {
              this.handleFcmMessage(message, false);
              this.fcmSwChannel.postMessage({ type: 'DELETE_FCM_BG_MESSAGE', id: message.messageId });
            } catch (error) {
              console.error('FCM background message handle error', error);
            }
          }

          break;
      }
    };

    this.fcmSwChannel.postMessage({ type: 'CHECK_FCM_BG_MESSAGES' });
  }

  private async onFCMToken(loggedIn: AuthState | null, oldToken: string | null, newToken: string | null) {
    const token = newToken ?? oldToken;

    if (!loggedIn || !newToken) {
      if (token) await this.deleteFcmDevice(token);
      return;
    }

    if (oldToken && newToken && oldToken !== newToken) await this.deleteFcmDevice(oldToken);

    if (token) {
      await this.insertFcmDevice(token);
      await this.setFcmDeviceInfo(token);
    }
  }

  private async deleteFcmDevice(token: string) {
    await firstValueFrom(
      this.gqlClient.mutate({
        mutation: deleteDeviceMutation,
        variables: {
          device_id: token,
        },
      }),
    );
  }

  private async insertFcmDevice(token: string) {
    await firstValueFrom(
      this.gqlClient
        .mutate({
          mutation: insertDeviceMutation,
          variables: {
            device_id: token,
          },
        })
        .pipe(
          retry({
            count: 5,
            delay: 1000,
          }),
        ),
    );
  }

  private async setFcmDeviceInfo(token: string) {
    await firstValueFrom(
      this.gqlClient.mutate({
        mutation: setDeviceInfoMutation,
        variables: {
          device_id: token,
          info: {
            lastVersion: this.appVersion,
            ModifiedDT: new Date().toISOString(),
            data: {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              OS: `Web - ${navigator.platform ?? (navigator as any).userAgentData?.platform}`,
              appName: 'NG2',
              version: this.appVersion,
              deviceInfo: {
                brand: navigator.userAgent,
              },
            },
          },
        },
      }),
    );
  }

  private handleFcmMessage(message: MessagePayload, foreground: boolean) {
    console.log('FCM message', { message, foreground });

    this.notificationComponent!.showToast({
      title: message.notification?.title,
      body: message.notification?.body,
      timeOut: foreground ? 5000 : 120000,
      data: {
        message,
        foreground,
      },
    });
  }

  onToastClick(toastRawData: Record<string, unknown> | undefined) {
    const toastData = toastRawData as { message: MessagePayload; foreground: boolean } | undefined;
    const msgData = toastData?.message?.data;

    const path = msgData?.path;
    const args = msgData?.args ? JSON.parse(msgData.args) : null;
    if (!path || !args) return;

    //TODO: mark notification as opened

    this.router.navigate([path], { queryParams: args, queryParamsHandling: 'merge' });
  }
}
