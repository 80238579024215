import { Injectable, inject } from '@angular/core';
import { EmlAttachment, EmlEmail } from '@rcg/core/models';
import { AttachmentsService } from '@rcg/core/services';
import replaceAsync from 'string-replace-async';
import { tagBlobUrl } from '../fn/blob';
import { ResolverCheckFn, ResolverResolveFn } from '../models/resolver.model';
import { blobCidRegex, cidRegex } from '../regex';
import { blobFromUrl, blobToBase64 } from '../util';
import { HTMLAttachmentResolver } from './resolver';

@Injectable({
  providedIn: 'root',
})
export class EmlCidHTMLAttachmentResolver extends HTMLAttachmentResolver<[string, EmlEmail]> {
  private readonly attachmentsService = inject(AttachmentsService);
  private readonly emlBaseUrl = 'https://cdn.assist.si/eml/';

  private async getEmlCidBlobUrl(emlId: string, emlAttachments: EmlAttachment[], cid: string) {
    const partId = emlAttachments.find((a) => a?.cid === cid)?.partId;
    if (!partId) return null;

    const url = `${this.emlBaseUrl}${emlId}/${partId}`;
    return this.attachmentsService.getAttachmentBlobUrl(url);
  }

  protected override check: ResolverCheckFn<[string, EmlEmail]> = (_, emlData) => {
    if (!emlData) return null;
    if (!emlData[1].attachments || emlData[1].attachments.length === 0 || !Array.isArray(emlData[1].attachments)) return null;

    return [cidRegex, ...emlData] as const;
  };

  protected override resolve: ResolverResolveFn<[string, EmlEmail]> = async (cids, updateProgress, emlId, eml) => {
    let done = 0;

    const promises = cids.map((cid) => {
      return tagBlobUrl(this.getEmlCidBlobUrl(emlId, eml.attachments, cid), 'cid', cid).then((b) => {
        updateProgress(++done / cids.length, { [cid]: b });
        return [cid, b] as const;
      });
    });

    return Object.fromEntries(await Promise.all(promises));
  };

  public override sealBlobs = (html: string) => {
    return html.replace(blobCidRegex, (_, cid: string) => {
      return `"cid:${cid}"`;
    });
  };

  public override bakeBlobs = (html: string) => {
    return replaceAsync(html, blobCidRegex, async (quotedUrl) => {
      const url = quotedUrl.substring(1, quotedUrl.length - 1);
      const blob = await blobFromUrl(url);
      const b64 = await blobToBase64(blob);

      return `"${b64}"`;
    });
  };
}
