export class ContactCardData {
  initials = () => this.name?.split(' ')?.map(n => n[0]?.toUpperCase())?.join('');

  constructor(
      public readonly id: number | null | undefined,
      public readonly title: string,
      public readonly img: string | null | undefined,
      public readonly name: string,
      public readonly email: string | null | undefined,
      public readonly phone: string[] | null | undefined,
  ) { }
}
