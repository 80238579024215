//FIXME: Use strong types
/* eslint-disable @typescript-eslint/no-explicit-any */

import { IOrganization } from './organization.model';

export interface IContact {
  id: number;
  user_id: number;
  external_id?: string;
  created_at: string;
  updated_at: string;
  organization_id: number;
  employer_id: number;
  last_name: string;
  first_name: string;
  email?: string;
  telephone?: any;
  data: any;
  organization?: IOrganization;
  employer?: IOrganization;
}
