import { Injectable } from '@angular/core';
import { OrderByExpression, WhereExpression } from '@npm-libs/ng-templater';
import { AuthService, RcgTenant, RcgUser } from '@rcg/auth';
import { isNonNullable } from '@rcg/utils/type.utils';
import * as dot from 'dot-object';
import { filter, firstValueFrom } from 'rxjs';
import { GqlInput } from '../models';

@Injectable({
  providedIn: 'root',
})
export class GqlResolverService {
  constructor(private authService: AuthService) {}

  async resolveGqlInputVariables<D = unknown>(gqlInput: GqlInput<D>): Promise<GqlInput<D>> {
    const newGqlInput = Object.assign({}, gqlInput);
    const variables = Object.assign({}, newGqlInput.variables ?? {});
    const resolveVariables = Object.assign({}, newGqlInput.resolveVariables ?? {});

    const defaultWhereExpression = newGqlInput.whereExpression ? Object.assign({}, newGqlInput.whereExpression) : null;
    const defaultOrderByExpressions =
      newGqlInput.orderByExpressions && newGqlInput.orderByExpressions.length > 0 ? [...newGqlInput.orderByExpressions] : null;

    if (Object.keys(variables).length === 0 && !defaultWhereExpression && !defaultOrderByExpressions) return newGqlInput;

    const authInfo = await Promise.race([
      firstValueFrom(this.authService.authInfo$.pipe(filter(isNonNullable))),
      new Promise<null>((res) => setTimeout(() => res(null), 5000)),
    ]);

    const user = authInfo?.user;
    const tenant = authInfo?.tenant;

    if (!user?.id) throw new Error('Cannot resolve user. User is null');
    if (!tenant?.id) throw new Error('Cannot resolve tenant. Tenant is null');

    // resolve where expression variables
    const expressionVars = defaultWhereExpression
      ? this.resolveWhereExpressionsVariables(resolveVariables, defaultWhereExpression, user, tenant)
      : { expression: null, expressionVars: {} };

    // resolve other variables
    Object.entries(resolveVariables).forEach((e) => {
      const [key, value] = e;
      const varResolveName = value as string;

      if (!(key in expressionVars)) {
        variables[key] = this.getVariable(varResolveName, user, tenant);
      }
    });

    const filters = newGqlInput.filters ? Object.assign({}, newGqlInput.filters ?? {}) : null;
    if (defaultWhereExpression && filters?.whereExpressions) {
      if ('predicates' in defaultWhereExpression) {
        defaultWhereExpression.predicates = [...defaultWhereExpression.predicates, ...filters.whereExpressions];
      }
    }

    const whereVariables = defaultWhereExpression ? { where: this.transpileWhereExpressions([defaultWhereExpression]) } : {};

    const orderByFiltersExpressions =
      filters?.orderByExpressions && filters.orderByExpressions.length > 0 ? filters.orderByExpressions : null;
    const orderByVariables =
      orderByFiltersExpressions || defaultOrderByExpressions
        ? { orderBy: this.transpileOrderByExpressions(orderByFiltersExpressions ? orderByFiltersExpressions : defaultOrderByExpressions!) }
        : {};

    const vars = defaultWhereExpression ? { ...variables, ...whereVariables, ...orderByVariables } : variables;

    return { ...newGqlInput, variables: vars };
  }

  // path is string like 'data.users.count'
  resolveGqlResponse(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: Record<string, any> | any[] | null | undefined,
    path: string,
  ) {
    if (response === undefined || response === null) return null;

    if (Array.isArray(response)) {
      return response[0];
    }

    if (typeof response === 'object') {
      const pathArray = path.split('.');
      let value = response;

      for (const key of pathArray) {
        if (value && typeof value === 'object') {
          value = value[key];
        } else {
          throw new Error('not an object');
          // break;
        }
      }

      return value;
    }

    throw new Error('resolve gql response - Unsupported response type');
  }

  private resolveWhereExpressionsVariables(
    resolveVariables: Record<string, unknown>,
    expression: WhereExpression | WhereExpression[],
    user: RcgUser,
    tenant: RcgTenant,
  ): Record<string, unknown> {
    const expressionVars: Record<string, unknown> = {};

    if ('condition' in expression && 'predicates' in expression) {
      if (Array.isArray(expression.predicates)) {
        expression.predicates.forEach((expr) => {
          const result = this.resolveWhereExpressionsVariables(resolveVariables, expr, user, tenant);
          Object.assign(expressionVars, result);
        });
      } else {
        const result = this.resolveWhereExpressionsVariables(resolveVariables, expression, user, tenant);
        Object.assign(expressionVars, result);
      }
    } else {
      if ('operator' in expression && 'field' in expression) {
        const fieldName = expression.field;
        if (fieldName in resolveVariables) {
          const variableName = resolveVariables[fieldName] as string;
          expression.value = this.getVariable(variableName, user, tenant);
          expressionVars[fieldName] = expression.value;
        }
      }
    }

    return expressionVars;
  }

  private getVariable(key: string, user: RcgUser, tenant: RcgTenant): unknown {
    switch (key) {
      case 'hasuraUserId':
        return user.id;
      case 'organizationId':
        return tenant.organization.id;
      case 'tenantId':
        return tenant.id;
      case 'organizationsShareType':
        return tenant.organizationShareType;
      default:
        throw new Error(`Error resolve variable: ${key} with resolve variable name. Not implemented handler for this gql variable`);
    }
  }

  private transpileOrderByExpressions(expressions: OrderByExpression[]) {
    return expressions.map(({ field, direction }) => {
      const sortBy: Record<string, unknown> = {};

      const dir = direction === 'ascending' ? 'asc_nulls_last' : 'desc_nulls_last';
      dot.set(field, dir, sortBy);

      return sortBy;
    });
  }

  private transpileWhereExpressions(expressions: WhereExpression[]) {
    const result: { [x: string]: unknown } = {};

    for (const expression of expressions) {
      Object.assign(result, this.transpileWhereExpression(expression));
    }

    return result;
  }

  private transpileWhereNotPredicates(predicates: WhereExpression[]) {
    const first = predicates.shift();
    if (!first) throw new Error('Invalid first where not predicate');

    const rest = predicates.map((p) => this.transpileWhereExpression(p)).map((p) => ({ _not: p }));
    return [this.transpileWhereExpression(first), ...rest];
  }

  private getConditionPredicates(expression: WhereExpression) {
    const result: { [x: string]: unknown } = {};
    if (!('condition' in expression)) throw new Error(`No condition in expressions. Expression: ${expression}`);

    switch (expression.condition) {
      case 'and':
      case 'or':
        result[`_${expression.condition}`] = expression.predicates.map((p) => this.transpileWhereExpression(p));
        break;
      case 'not':
        result[`_not`] = this.transpileWhereExpression(expression.predicates[0]);
        break;
      case 'and not':
        result['_and'] = this.transpileWhereNotPredicates(expression.predicates);
        break;
      case 'or not':
        result['_or'] = this.transpileWhereNotPredicates(expression.predicates);
        break;
      default:
        throw new Error(`Invalid expression condition: ${expression.condition}`);
    }
    return result;
  }

  private transpileWhereExpression(expression: WhereExpression) {
    const result: { [x: string]: unknown } = {};

    if ('raw' in expression) {
      return expression.raw;
    }

    if ('field' in expression && 'condition' in expression && 'predicates' in expression && Array.isArray(expression.predicates)) {
      const nestedExpression: { [x: string]: unknown } = {};
      nestedExpression[`${expression.field}`] = {
        ...this.getConditionPredicates(expression),
      };
      return nestedExpression;
    }

    if ('condition' in expression) {
      return this.getConditionPredicates(expression);
    }

    if ('operator' in expression) {
      let op: string;
      let val = expression.value;

      switch (expression.operator) {
        case 'eq':
          op = expression.ignoreCase ? 'ilike' : 'eq';
          if (expression.ignoreCase) val = `${val}`.replaceAll('%', '\\%');
          break;
        case 'ne':
          op = expression.ignoreCase ? 'nilike' : 'neq';
          if (expression.ignoreCase) val = `${val}`.replaceAll('%', '\\%');
          break;
        // case 'lt':
        // case 'gt':
        //   op = expression.operator;
        //   break;
        case 'lt':
          op = 'lt';
          break;
        case 'gt':
          op = 'gt';
          break;
        case 'le':
        case 'lte':
          op = 'lte';
          break;
        case 'ge':
        case 'gte':
          op = 'gte';
          break;
        case 'in':
          op = 'in';
          break;
        case 'nin':
          op = 'nin';
          break;
        case 'startswith':
          op = expression.ignoreCase ? 'ilike' : 'like';
          val = `${`${val}`.replaceAll('%', '\\%')}%`;
          break;
        case 'endswith':
          op = expression.ignoreCase ? 'ilike' : 'like';
          val = `%${`${val}`.replaceAll('%', '\\%')}`;
          break;
        case 'contains':
          op = expression.ignoreCase ? 'ilike' : 'like';
          val = `%${`${val}`.replaceAll('%', '\\%')}%`;
          break;
        case 'like':
          op = expression.ignoreCase ? 'ilike' : 'like';
          break;
        case 'wildcard':
          op = 'like';
          val = `${val}`.replaceAll('*', '%');
          break;
        case 'isnull':
          op = 'is_null';
          val = true;
          break;
        case 'isnotnull':
          op = 'is_null';
          val = false;
          break;
        case 'isempty':
          op = 'eq';
          val = '';
          break;
        case 'isnotempty':
          op = 'neq';
          val = '';
          break;
        case 'jsoncontains':
          op = 'contains';
          break;
        default:
          throw new Error(`Invalid expression operator: ${expression.operator}`);
      }

      dot.set(expression.field, { [`_${op}`]: val }, result);

      return result;
    }

    throw new Error(`Invalid expression: ${JSON.stringify(expression)}`);
  }
}
