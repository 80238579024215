export const blobFromUrl = (url: string) => fetch(url).then((r) => r.blob());

export const blobToBase64 = (blob: Blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);

  return new Promise<string>((res) => {
    reader.onloadend = () => {
      res(reader.result as string);
    };
  });
};
