import { Injectable, inject } from '@angular/core';
import { AttachmentsService } from '@rcg/core/services';
import { tagBlobUrl } from '../fn/blob';
import { ResolverCheckFn, ResolverResolveFn } from '../models/resolver.model';
import { blobRcgCidRegex, cidRegex } from '../regex';
import { HTMLAttachmentResolver } from './resolver';

@Injectable({
  providedIn: 'root',
})
export class FileHashCidHTMLAttachmentResolver extends HTMLAttachmentResolver<[]> {
  private readonly attachmentsService = inject(AttachmentsService);

  protected override check: ResolverCheckFn<[]> = () => [cidRegex] as const;

  protected override resolve: ResolverResolveFn<[]> = async (cids, updateProgress) => {
    let done = 0;

    const promises = cids.map((cid) =>
      tagBlobUrl(this.attachmentsService.getFileHashAttachmentBlobUrl(cid), 'rcgCID', cid).then((b) => {
        updateProgress(++done / cids.length, { [cid]: b });
        return [cid, b] as const;
      }),
    );

    return Object.fromEntries(await Promise.all(promises));
  };

  public override sealBlobs = (html: string) => {
    return html.replace(blobRcgCidRegex, (_, cid: string) => {
      return `"rcgCID:${cid}"`;
    });
  };

  public override bakeBlobs = (html: string) => {
    return html.replace(blobRcgCidRegex, (a, cid) => {
      return `"rcgCID:${cid}"`;
    });
  };
}
