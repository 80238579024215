import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_AUTH_TOKEN_GETTER, AppAuthTokenGetter } from '@rcg/standalone/injection-tokens/auth-token';
import { sanitizeHtmlEntities } from '@rcg/standalone/utils/html-utils';
import { firstValueFrom } from 'rxjs';
import { EmlAttachment, EmlEmail } from '../models/eml.model';
import { AttachmentsService } from './attachments.service';

@Injectable({
  providedIn: 'root',
})
export class EmlService {
  readonly cdnBaseUrl = 'https://cdn.assist.si';

  constructor(
    @Inject(APP_AUTH_TOKEN_GETTER) private getAuthToken: AppAuthTokenGetter,
    private http: HttpClient,
    private attachmentsService: AttachmentsService,
  ) {}

  private async _getEmail(type: 'eml' | 'msg', emlId: string): Promise<EmlEmail> {
    const url = `${this.cdnBaseUrl}/${type}/${emlId}/json`;
    const token = await this.getAuthToken();

    const res = await firstValueFrom(
      this.http.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'json',
        observe: 'response',
      }),
    ).catch((error) => {
      if (typeof error.error === 'object') throw error.error;
      throw error;
    });

    const eml = res.body as EmlEmail;

    if (!eml) {
      throw new Error('No eml server response');
    }
    return {
      ...eml,
      attachments: Array.isArray(eml.attachments) ? eml.attachments.map((a) => ({ ...a, emlId: +emlId })) : [],
    };
  }

  async getEmlEmail(emlId: string): Promise<EmlEmail> {
    return this._getEmail('eml', emlId);
  }

  async getMsgEmail(emlId: string): Promise<EmlEmail> {
    return this._getEmail('msg', emlId);
  }

  getEmlBodyHtml(eml: EmlEmail): string {
    if (!eml?.body) {
      return '';
    }

    if (eml.body?.html) {
      return eml.body.html;
    }
    if (eml.body?.textAsHtml) {
      return eml.body?.textAsHtml;
    }
    if (eml.body?.text) {
      return eml.body.text;
    }
    return '';
  }

  getEmlBodyPlainText(eml: EmlEmail): string {
    if (!eml?.body) {
      return '';
    }
    if (eml.body?.text) {
      return sanitizeHtmlEntities(eml.body.text);
    }
    if (eml.body?.htmlAsText) {
      return eml.body.htmlAsText;
    }
    return '';
  }

  getEmlAttachments(eml: EmlEmail): EmlAttachment[] {
    if (!Array.isArray(eml?.attachments)) {
      return [];
    }

    return eml.attachments.filter((a) => a?.contentDisposition === 'attachment');
  }

  async downloadEmlAttachmentBlob(attachment: EmlAttachment): Promise<Blob> {
    const url = `${this.cdnBaseUrl}/eml/${attachment.emlId}/${attachment.partId}`;
    return await this.attachmentsService.getAttachmentBlob(url);
  }

  async downloadEmlAttachmentBlobUri(attachment: EmlAttachment): Promise<string> {
    const url = `${this.cdnBaseUrl}/eml/${attachment.emlId}/${attachment.partId}`;
    return await this.attachmentsService.getAttachmentBlobUrl(url);
  }
}
