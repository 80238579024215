export function deepEqual(obj1: unknown, obj2: unknown) {
  // Base case: If both objects are identical, return true.
  if (obj1 === obj2) {
    return true;
  }

  // Check if both objects are objects and not null.
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return false;
  }

  // Get the keys of both objects.
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same.
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Iterate through the keys and compare their values recursively.
  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual((obj1 as Record<string, unknown>)[key], (obj2 as Record<string, unknown>)[key])) {
      return false;
    }
  }

  // If all checks pass, the objects are deep equal.
  return true;
}
