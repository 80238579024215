import { Injectable } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';

import * as Get from "@npm-libs/ng-getx";

import { IRouterState } from '../models/router.model';
import * as fromFeature from '../store/index';

@Get.NgAutoDispose
@Injectable({
  providedIn: 'root',
})
export class RouterStateService {
  constructor(private store: Store) { }

  readonly state$ = this.store.select(selectRouterState$);
  readonly params$ = this.store.select(selectRouterParams$);
  readonly queryParams$ = this.store.select(selectRouterQueryParams$);
  readonly url$ = this.store.select(selectRouterUrl$);
  readonly navigationId$ = this.store.select(selectRouterNavigationId$);

  readonly pathFromRootR = this.store.select(selectRouterPathFromRoot$).obs();
}

// private router store selectors not exposed to public api

const selectRouterState$ = createSelector(
  fromFeature.selectCoreRouterState$,
  (state) => state?.state as IRouterState | undefined
);

const selectRouterParams$ = createSelector(
  selectRouterState$,
  (state) => state?.params ?? null
);

const selectRouterQueryParams$ = createSelector(
  selectRouterState$,
  (state) => state?.queryParams ?? null
);

const selectRouterPathFromRoot$ = createSelector(
  selectRouterState$,
  (state) => state?.pathFromRoot ?? []
);

const selectRouterUrl$ = createSelector(
  selectRouterState$,
  (state) => state?.url ?? null
);

const selectRouterNavigationId$ = createSelector(
  fromFeature.selectCoreRouterState$,
  (state) => state.navigationId ?? null
);
