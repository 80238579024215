import { DocumentNode, gql } from '@apollo/client/core';
import { OrderByExpression, WhereExpression } from '@npm-libs/ng-templater';
import { FilterExpressions } from './filter-expressions';

export type GqlQueryType = 'subscription' | 'query';

export interface GqlInputSearch {
  prefix?: string;
  suffix?: string;
  variable?: string;
}

export interface GqlInput<D = unknown> {
  limit?: number;
  variables?: Record<string, unknown> | undefined | null;
  query: string | DocumentNode;
  type: GqlQueryType;
  searchable?: boolean;
  search?: GqlInputSearch;
  resolveVariables?: Record<string, unknown> | undefined | null;
  responsePath?: string | undefined | null;
  whereExpression?: WhereExpression;
  orderByExpressions?: OrderByExpression[];
  filters?: FilterExpressions | null | undefined;
  dataMapper?: (data: D[], expandedIds: number[]) => D[];
}

export function normalizeGqlInputQuery(query: GqlInput['query']): DocumentNode {
  if (typeof query === 'string') return gql(query);
  return query;
}
