import { gql } from '@apollo/client/core';

export const insertDeviceMutation = gql`
mutation insertDevice(
  $device_id: String!
) {
  action: insert_identity_devices_one(
    object: {
      device_id: $device_id
    },
    on_conflict: {
      constraint: devices_device_id_key,
      update_columns: [device_id]
    }
  ) {
    id
  }
}
`;

export const deleteDeviceMutation = gql`
mutation deleteDevice(
  $device_id: String!
) {
  action: deleteDevice(
    device_id: $device_id
  ) {
    deleted
  }
}
`;

export const setDeviceInfoMutation = gql`
mutation setDeviceInfo(
  $device_id:String!,
  $info: jsonb,
) {
  action: update_identity_devices(
    where: {
      device_id: {_eq: $device_id}
    },
    _set: {
      info: $info
    }
  ) {
    returning {
      id
    }
  }
}
`;
