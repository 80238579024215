import { gql } from '@apollo/client/core';

export const attachmentsSubscription = gql`
  subscription AttachmentQuery($ids: [bigint!]) {
    data: global_attachments(where: { id: { _in: $ids } }) {
      id
      file_name
      file_size
      content_type
      ai_label: ai_data(path: "classification.labels[0]")
    }
  }
`;

export const getAttachmentViewTokenQuery = gql`
  query GetAttachmentViewToken($attachment_id: bigint!) {
    data: attachment_generateViewToken(attachment_id: $attachment_id) {
      token
    }
  }
`;

export const getAttachmentNestedViewTokenQuery = gql`
  query GetAttachmentNestedViewToken($attachment_id: bigint!, $sub_id: String!) {
    data: attachment_generateNestedViewToken(attachment_id: $attachment_id, sub_id: $sub_id) {
      token
    }
  }
`;

export const attachmentIdByHashQuery = gql`
  query AttachmentIdByHash($hash: String!) {
    data: global_attachments(where: { hash: { _eq: $hash } }) {
      id
    }
  }
`;

export const oneDriveAttachmentAppQuery = gql`
  query GetOneDriveAttachmentApp($origin: String!) {
    app: identity_onedrive_attachment_apps_by_pk(origin: $origin) {
      client_id
      tenant_id
      redirect_uri
      mobile_only
    }
  }
`;
