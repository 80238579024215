import { FrameDOMParser } from './dom-utils';

export function sanitizeHtmlEntities(html: string | null | undefined) {
  const trimmedHtml = html?.trim();
  if (!trimmedHtml) {
    return '';
  }
  const sanitized = trimmedHtml
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&apos;');
  return `<pre>${sanitized}<pre>`;
}

const parser = new FrameDOMParser();

export type HTML = string | HTMLElement | Document | Document | null | undefined;

export async function extractHtmlBodyElement(html: HTML) {
  let el: HTMLElement | undefined;

  if (html instanceof HTMLElement) {
    el = html;
  } else if (typeof html === 'string') {
    el = (await parser.parseFromString(html, 'text/html')).body;
  } else if (html) {
    el = html.body;
  }

  if (!el) return undefined;

  let drill: HTMLBodyElement | HTMLHtmlElement | null;

  while ((drill = el.querySelector('body') ?? el.querySelector('html'))) {
    el = drill;
  }

  return el;
}

export async function htmlToPlainText(html: HTML, clean = true): Promise<string> {
  const el = await extractHtmlBodyElement(html);
  if (!el) return '';

  if (!clean) return el.innerText?.trim();

  const emptyParagraphs = [...el.querySelectorAll('p')].filter((p) => p.innerHTML === '');

  for (const p of emptyParagraphs) {
    p.innerHTML = '&nbsp;';
  }

  return el.innerText?.trim()?.replaceAll('\n\n', '\n') ?? '';
}

export async function htmlHasVisibleContent(html: HTML) {
  const el = await extractHtmlBodyElement(html);
  if (!el) return false;

  if ((el.innerText?.trim() ?? '').length) return true;

  const checkTags = ['img', 'audio', 'video', 'picture', 'iframe', 'object', 'embed'] as const;

  for (const t of checkTags) {
    if (el.getElementsByTagName(t).length) return true;
  }

  return false;
}

export function replaceTextLineBreaks(text: string | undefined | null): string {
  if (!text) {
    return '';
  }
  return text.replace(/(?:\r\n|\r|\n)/g, '<br>') ?? '';
}

export async function decodeHtmlEntities(html: string | null | undefined) {
  const trimmedHtml = html?.trim();
  if (!trimmedHtml) {
    return '';
  }
  const dom = await parser.parseFromString(trimmedHtml, 'text/html');
  return dom.body.textContent;
}

export async function normalizeAndCleanHTML(html: string) {
  const doc = await normalizeAndCleanHTMLAsDoc(html);
  return doc.documentElement.outerHTML;
}

export async function normalizeAndCleanHTMLAsDoc(html: string) {
  const doc = await parser.parseFromString(html, 'text/html');

  const darkReaderStyles = doc.querySelectorAll('style.darkreader');
  darkReaderStyles.forEach((s) => s.remove());

  return doc;
}
