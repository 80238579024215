import { Inject, Injectable, Optional } from '@angular/core';
import { gql } from '@apollo/client/core';

import * as Get from '@npm-libs/ng-getx';
import { USER_SETTINGS_SERVICE_OPTIONS, UserSettingsServiceOptions } from '@rcg/core/services';
import { GraphqlClientService } from '@rcg/graphql';
import { deepDistinctUntilChanged } from '@rcg/standalone/rxjs-operators/deep-distinct-until-changed';
import { Observable, catchError, distinctUntilChanged, firstValueFrom, map, merge, of, shareReplay, startWith, switchMap, tap } from 'rxjs';
import { SavedShortcuts } from '../models';

const settingsSubscription = gql`
  subscription UserSettingsSubscription($tenantIdIn: [bigint!]!, $userId: Int!) {
    settings: identity_user_settings(where: { _and: [{ tenant_id: { _in: $tenantIdIn } }, { user_id: { _eq: $userId } }] }) {
      key
      value
    }
  }
`;

const setSettingMutation = gql`
  mutation SetSettingMutation($tenantId: bigint!, $key: String!, $value: jsonb) {
    insert_identity_user_settings_one(
      object: { tenant_id: $tenantId, key: $key, value: $value }
      on_conflict: { constraint: user_settings_user_id_key_tenant_id_key, update_columns: [value] }
    ) {
      id
    }
  }
`;

const defaultSettings = {
  locale: 'sl_SI',
  shortcuts: {} as SavedShortcuts,
  defaultRoute: '/',
};

const tenantSpecificSettings = ['defaultRoute'] as const;
const undefinedSettingsWithoutTenant = ['defaultRoute'] as const;

type UserSettings = typeof defaultSettings;
type UndefinedSettingsWithoutTenant = (typeof undefinedSettingsWithoutTenant)[number];

const nullUserSettingsLocalStorageKey = 'rcgNgNullUserSettings';

@Get.NgAutoDispose
@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  private userInfo$ = (this.options?.userInfo$ ?? of(undefined)).pipe(
    distinctUntilChanged(),
    map((info) => (info ? info : {})),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  private nullUserSettingsR = new Get.Rx<UserSettings>({
    ...defaultSettings,
    ...JSON.parse(window.localStorage.getItem(nullUserSettingsLocalStorageKey) ?? '{}'),
  });

  private settingsR = new Get.Rx<UserSettings>({
    ...defaultSettings,
    ...JSON.parse(window.localStorage.getItem(nullUserSettingsLocalStorageKey) ?? '{}'),
  });

  public locale$ = this.setting$('locale');
  public shortcuts$ = this.setting$('shortcuts');
  public defaultRoute$ = this.setting$('defaultRoute');

  private readonly settingsLoadedR = new Get.Rx<boolean>(false);
  public readonly settingsLoaded$ = this.settingsLoadedR.value$.pipe(startWith(this.settingsLoadedR.value), distinctUntilChanged());

  constructor(
    @Inject(USER_SETTINGS_SERVICE_OPTIONS) @Optional() private options: UserSettingsServiceOptions | null,
    private gqlClient: GraphqlClientService,
  ) {
    merge(this.nullUserSettingsR.value$, this.settingsR.value$)
      .pipe(deepDistinctUntilChanged())
      .subscribe((settings) => {
        this.nullUserSettingsR.data = settings;

        const filteredSettings = {
          ...settings,
          shortcuts: undefined,
          defaultRoute: undefined,
        };

        return window.localStorage.setItem(nullUserSettingsLocalStorageKey, JSON.stringify(filteredSettings));
      });

    this.settingsR.subscribeTo(
      this.userInfo$.pipe(
        tap(() => (this.settingsLoadedR.data = false)),
        switchMap(({ tenantId, userId }) => {
          if (!userId) return this.nullUserSettingsR.value$;

          return this.gqlClient
            .subscribe<{
              settings: { key: string; value: unknown }[];
            }>({
              query: settingsSubscription,
              variables: {
                tenantIdIn: tenantId ? [0, tenantId] : [0],
                userId: userId,
              },
            })
            .pipe(
              map((data) => Object.fromEntries(data.settings.map(({ key, value }) => [key, value]))),
              catchError((e) => {
                console.error('Error while loading user settings:', e);
                return of({});
              }),
              map((settings) => ({
                ...defaultSettings,
                ...settings,
                ...(tenantId ? {} : Object.fromEntries(undefinedSettingsWithoutTenant.map((k) => [k, undefined]))),
              })),
            );
        }),
        tap(() => setTimeout(() => (this.settingsLoadedR.data = true), 1)),
      ),
    );
  }

  private setting$<K extends keyof UserSettings>(key: K) {
    return this.settingsR.value$.pipe(
      map(
        (s) =>
          s[key] ??
          ((undefinedSettingsWithoutTenant as unknown as (keyof UserSettings)[]).includes(key) ? undefined : defaultSettings[key]),
      ),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: false }),
    ) as Observable<K extends UndefinedSettingsWithoutTenant ? UserSettings[K] | undefined : UserSettings[K]>;
  }

  public async set(key: keyof UserSettings, value: unknown) {
    const oldSettings = this.settingsR.value;
    const oldValue = oldSettings[key];

    if (JSON.stringify(value) === JSON.stringify(oldValue)) return;

    const isTenantSpecific = (tenantSpecificSettings as unknown as (keyof UserSettings)[]).includes(key);

    this.settingsR.data = {
      ...oldSettings,
      [key]: value,
    } as UserSettings;

    const userInfo = await firstValueFrom(this.userInfo$);

    if (!userInfo.userId) return;
    if (isTenantSpecific && !userInfo.tenantId) return;

    try {
      await firstValueFrom(
        this.gqlClient.mutate({
          mutation: setSettingMutation,
          variables: {
            tenantId: isTenantSpecific ? userInfo.tenantId : 0,
            key,
            value,
          },
        }),
      );
    } catch (error) {
      console.error('Failed to change user setting "%s" to "%s":\n%O', key, value, error);

      this.settingsR.data = {
        ...this.settingsR.value,
        [key]: oldValue,
      } as UserSettings;
    }
  }
}
