import { combineLatest, concat, interval, of } from 'rxjs';
import { first } from 'rxjs/operators';

import { ApplicationRef, Inject, Injectable, Optional } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { UPDATE_SERVICE_OPTIONS, UpdateServiceOptions } from './update-options-injection-token';

const oneMonthMs = 60 * 60 * 1000 * 24 * 30;

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  private updateSnackBar?: MatSnackBarRef<TextOnlySnackBar>;

  constructor(
    private snackBar: MatSnackBar,
    swUpdate: SwUpdate,
    appRef: ApplicationRef,
    @Inject(UPDATE_SERVICE_OPTIONS) @Optional() options: UpdateServiceOptions | null,
  ) {
    try {
      combineLatest([swUpdate.versionUpdates, options?.autoUpdate$ ?? of(false)]).subscribe(([vu, autoUpdate]) => {
        switch (vu.type) {
          case 'VERSION_DETECTED':
          case 'NO_NEW_VERSION_DETECTED':
            //? When the update is downloaded VERSION_READY will trigger, so we do nothing here and wait for that.
            break;
          case 'VERSION_READY':
            if (autoUpdate) this.update();
            else this.showUpdateSnackbar('Posodobitev aplikacije je na voljo', 'Posodobi');
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.error('Update installation error:', vu.error);
            this.showUpdateSnackbar('Posodobitev aplikacije ni uspela', 'Poskusi ponovno');
            break;
          default:
            console.warn('Unhandled version update event:', vu);
            break;
        }
      });

      const appIsStable$ = appRef.isStable.pipe(first((isStable) => isStable));

      const everyHour$ = interval(60 * 60 * 1000);
      const everyHoursOnceAppIsStable$ = concat(appIsStable$, everyHour$);

      everyHoursOnceAppIsStable$.subscribe(async () => {
        if (swUpdate.isEnabled) {
          try {
            await swUpdate.checkForUpdate();
          } catch (e) {
            console.error('Update check failed:', e);
          }
        }
      });
    } catch (e) {
      console.error('Update check registration failed:', e);
    }
  }

  private async showUpdateSnackbar(message: string, action?: string | undefined) {
    try {
      this.updateSnackBar?.dismiss();
    } catch (_) {
      //ignore
    }

    this.updateSnackBar = this.snackBar.open(message, action, {
      duration: oneMonthMs,
      politeness: 'assertive',
    });

    this.updateSnackBar.onAction().subscribe(() => this.update());
  }

  private async update() {
    window.location.reload();
  }
}
