import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { attachmentIdentifier } from '@rcg/core/utils/attachment';
import { GraphqlClientService } from '@rcg/graphql';
import { APP_AUTH_TOKEN_GETTER, AppAuthTokenGetter } from '@rcg/standalone/injection-tokens/auth-token';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  ReplaySubject,
  catchError,
  defaultIfEmpty,
  firstValueFrom,
  map,
  of,
  retry,
  switchMap,
  timer,
} from 'rxjs';
import {
  attachmentIdByHashQuery,
  attachmentsSubscription,
  getAttachmentNestedViewTokenQuery,
  getAttachmentViewTokenQuery,
  oneDriveAttachmentAppQuery,
} from '../gql/attachments.gql';
import { EmlAttachment } from '../models';
import { ATTACHMENT_BLOB_URL_LOADING, Attachment, AttachmentBlobUrl, AttachmentBlobUrls } from '../models/attachments.model';

export interface OneDriveAttachmentApp {
  client_id: string;
  tenant_id: string;
  redirect_uri: string;
  mobile_only: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AttachmentsService {
  readonly attachmentBaseUrl = 'https://cdn.assist.si/attachment';
  readonly sqlFileHashAttachmentBaseUrl = 'https://cdn.assist.si/sql_attachment_by_file_hash/';

  private oneDriveAttachmentAppB$ = new BehaviorSubject<OneDriveAttachmentApp | null>(null);
  public oneDriveAttachmentApp$ = this.oneDriveAttachmentAppB$.asObservable();

  constructor(
    @Inject(APP_AUTH_TOKEN_GETTER) private getAuthToken: AppAuthTokenGetter,
    private http: HttpClient,
    private graphQlClient: GraphqlClientService,
  ) {
    this._tryGetOneDriveAttachmentApp();
  }

  private async _tryGetOneDriveAttachmentApp() {
    let tries = 0;

    while (tries < 10) {
      try {
        this._getOneDriveAttachmentApp();
        break;
      } catch (error) {
        tries++;

        console.warn(`Failed to get OneDrive attachment app (attempt ${tries}/10), will retry in 1s.`, error);
        await firstValueFrom(timer(1000));
      }
    }
  }

  private async _getOneDriveAttachmentApp() {
    const data = await firstValueFrom(
      this.graphQlClient.anonymousQuery<{ app: OneDriveAttachmentApp | null }>({
        query: oneDriveAttachmentAppQuery,
        variables: {
          origin: window.location.origin,
        },
      }),
    );

    this.oneDriveAttachmentAppB$.next(data.app);
  }

  subscribeToAttachments(attachmentIds: number[]): Observable<Attachment[]> {
    return this.graphQlClient
      .subscribe<{ data?: Attachment[] }>({
        query: attachmentsSubscription,
        variables: { ids: attachmentIds.map((id) => (id < 0 ? -id : id)) },
      })
      .pipe(map((result) => (result?.data ?? []).map((attachment) => ({ ...attachment, inline: attachmentIds.includes(-attachment.id) }))));
  }

  async uploadAttachments(files: File[]): Promise<number[]> {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file, file.name);
    }

    const url = await this.attachmentUploadUrl();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = await firstValueFrom(this.http.post(url, formData));
    // if (!(response.header.status === 200 || response.header.status === 201)) {
    //   throw Error(`Response error. Status: ${response.header.status}.`);
    // }

    if (!response?.attachments?.files) {
      throw new Error('Wrong upload response. Response is empty');
    }
    const attachments = response?.attachments?.files;

    if (!attachments) {
      throw Error('Wrong files response. Files empty');
    }
    if (attachments.length === 0) {
      throw Error('Wrong files response. Empty files array');
    }
    if (Array.isArray(attachments)) {
      return attachments.map((a) => a.id);
    }

    return [attachments.id];
  }

  async importAttachments(urls: string[]): Promise<number[]> {
    const url = await this.attachmentUploadUrl('import');
    const attachments = ((await firstValueFrom(this.http.post(url, { urls }))) as { attachments?: number[] }).attachments;

    if (!attachments || !attachments.length) {
      throw Error('Server responded with empty attachments!');
    }

    return attachments;
  }

  getAttachmentsBlobUrls(attachments: (Attachment | EmlAttachment)[], thumbnail = true): AttachmentBlobUrls {
    const blobUrls: AttachmentBlobUrls = {};

    if (!attachments?.length) return blobUrls;

    for (const attachment of attachments) {
      const id = attachmentIdentifier(attachment);

      const subj = new ReplaySubject<AttachmentBlobUrl>(1);
      subj.next(ATTACHMENT_BLOB_URL_LOADING);

      blobUrls[id] = subj;

      this.getAttachmentBlobUrlById(id, thumbnail)
        .then(async (blobUrl) => {
          subj.next(blobUrl);
          subj.complete();
        })
        .catch((e) => {
          console.warn('getAttachmentsBlobUrls attachment error', e);
          subj.next(undefined);
          subj.complete();
        });
    }

    return blobUrls;
  }

  async getAttachmentBlobUrlById(id: `${number}/${string}` | number, thumbnail = true): Promise<string> {
    return await this.getAttachmentBlobUrl(`${this.attachmentBaseUrl}/${id}/${thumbnail ? 'thumb' : ''}`);
  }

  async getAttachmentBlobById(id: `${number}/${string}` | number, thumbnail = true): Promise<Blob> {
    return await this.getAttachmentBlob(`${this.attachmentBaseUrl}/${id}/${thumbnail ? 'thumb' : ''}`);
  }

  async getFileHashAttachmentBlobUrl(fileHash: string, schema = 'rcg_attachments', tableLocation = 'new'): Promise<string | undefined> {
    const url = `${this.sqlFileHashAttachmentBaseUrl}${schema}/${tableLocation}/${fileHash}`;
    return await this.getAttachmentBlobUrl(url);
  }

  async getAttachmentBlob(url: string): Promise<Blob> {
    const token = await this.getAuthToken();
    const blob = await firstValueFrom(this.downloadBlob(url, token!).pipe(defaultIfEmpty(null)));

    if (!blob) throw Error('No blob response');
    return blob;
  }

  getBlobUri(blob: Blob): string {
    const blobUri = URL.createObjectURL(blob);

    if (!blobUri) throw Error('No blob uri response');
    return blobUri;
  }

  async getAttachmentBlobUrl(url: string): Promise<string> {
    const blob = await this.getAttachmentBlob(url);
    return this.getBlobUri(blob);
  }

  private downloadBlob(url: string, token: string): Observable<Blob> {
    return this.http
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
      })
      .pipe(
        retry({ count: 3, delay: (_error, retryIndex) => timer(Math.pow(2, retryIndex - 1) * 200) }), //? Wait for attachment to appear, give up after 12 seconds
        catchError(() => {
          return EMPTY;
        }),
      );
  }

  private async attachmentUploadUrl(
    endpoint: 'upload' | 'import' = 'upload',
    schema = 'global',
    attachmentsTable = 'attachments',
    parent:
      | {
          formRecordId: string;
          formRecordIdFieldName: string;
        }
      | undefined = undefined,
  ): Promise<string> {
    const token = await this.getAuthToken();

    const data = {
      token: token,
      app: 'AIO',
      attachmentTableName: `${schema}_${attachmentsTable}`,
      ...parent,
    };

    const params = Object.entries(data)
      .map(([k, v]) => `${k}=${encodeURIComponent(v ?? 'null')}`)
      .join('&');

    return `${this.attachmentBaseUrl}/${endpoint}?${params}`;
  }

  public getAttachmentViewToken(attachment_id: number, sub_id?: string) {
    return this.graphQlClient
      .query<{ data?: { token: string } }>({
        query: sub_id ? getAttachmentNestedViewTokenQuery : getAttachmentViewTokenQuery,
        variables: {
          attachment_id,
          sub_id,
        },
      })
      .pipe(map((data) => data.data?.token ?? null));
  }

  public async getAttachmentIdFromHash(hash: string) {
    const response = await firstValueFrom(
      this.graphQlClient.query<{ data?: { id: number }[] }>({
        query: attachmentIdByHashQuery,
        variables: {
          hash,
        },
      }),
    );

    if (!response.data || response.data.length !== 1) throw new Error(`[getAttachmentIdFromHash] Invalid response data: ${response.data}`);
    return response.data[0].id;
  }

  makeAttachmentSubscription({
    attachmentIds$,
    getAttachmentsForDownload,
    addBlobUrls,
    setAttachments,
  }: {
    attachmentIds$: Observable<number[] | undefined>;
    getAttachmentsForDownload: (attachments: Attachment[]) => Attachment[];
    addBlobUrls: (blobUrls: AttachmentBlobUrls | null) => void;
    setAttachments: (attachments: Attachment[]) => void;
  }) {
    return attachmentIds$
      .pipe(
        switchMap((attachmentIds) => {
          if (!Array.isArray(attachmentIds) || !attachmentIds.length) {
            return of([]);
          }
          return this.subscribeToAttachments(attachmentIds).pipe(
            catchError((err) => {
              console.error('Subscribe to attachments error', err);
              return of([]);
            }),
          );
        }),
      )
      .subscribe({
        next: async (attachments: Attachment[]) => {
          if (attachments.length > 0) {
            const attachmentsForDownload = getAttachmentsForDownload(attachments);
            if (attachmentsForDownload.length > 0) {
              addBlobUrls(await this.getAttachmentsBlobUrls(attachmentsForDownload));
            }
          }
          setAttachments(attachments);
        },
        error: (error) => {
          console.error('Attachment subscription error', error);
        },
      });
  }
}
