export async function tagBlobUrl(urlP: Promise<string | null | undefined>, tagName: string, tag: string) {
  try {
    const blobUrl = await urlP;
    if (!blobUrl) return null;

    return `"${blobUrl}#${tagName}=${tag}"`;
  } catch (error) {
    console.warn(`Failed to get blob url for ${tagName}=${tag}`, error);
    return null;
  }
}
export function replaceHtmlRegexWithBlobUrls(html: string, blobUrls: { [cid: string]: string | null }, regex: RegExp): string {
  const notNullBlobUriEntries = Object.entries(blobUrls).filter(([, v]) => v);

  if (!notNullBlobUriEntries.length) return html;
  const notNullBlobUris = Object.fromEntries(notNullBlobUriEntries);

  return html.replace(regex, (match, cid) => {
    return notNullBlobUris[cid] ?? match;
  });
}
