import { EmlEmail } from '@rcg/core/models';

import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ACidHTMLAttachmentResolver } from '../utils/attachment/resolvers/acid';
import { AidHTMLAttachmentResolver } from '../utils/attachment/resolvers/aid';
import { EmlCidHTMLAttachmentResolver } from '../utils/attachment/resolvers/cid';
import { FileHashCidHTMLAttachmentResolver } from '../utils/attachment/resolvers/rcg-cid';

@Injectable({
  providedIn: 'root',
})
export class HTMLAttachmentResolverService {
  private readonly resolvers = [
    inject(ACidHTMLAttachmentResolver),
    inject(EmlCidHTMLAttachmentResolver),
    inject(FileHashCidHTMLAttachmentResolver),
    inject(AidHTMLAttachmentResolver),
  ];

  private readonly reversedResolvers = this.resolvers.slice().reverse();

  public resolve(
    html: string | null | undefined,
    unresolveableAttachments: string[],
    emlData?: [string, EmlEmail],
  ): [Observable<string>, Observable<number>, Observable<string[]>] {
    if (!html) return [of(''), of(1), of([])];

    const result$ = new BehaviorSubject<string>(html);
    const progress$ = new BehaviorSubject<number>(0);
    const unresolved$ = new BehaviorSubject<string[]>([]);

    const resolverCount = this.resolvers.length;
    const scale = 1 / resolverCount;

    const updateProgress = (progress: number, html: string | undefined, i: number) => {
      progress$.next(i / resolverCount + progress * scale);
      if (html) result$.next(html);
    };

    (async () => {
      for (let i = 0; i < resolverCount; i++) {
        const resolver = this.resolvers[i];
        const next = await resolver.resolveAttachments(result$.value, unresolveableAttachments, emlData, (p, h) => updateProgress(p, h, i));
        progress$.next((i + 1) / resolverCount);
        result$.next(next[0] as string);
        unresolved$.next([...unresolved$.getValue(), ...(next[1] as string[])]);
      }

      result$.complete();
      progress$.complete();
    })();

    return [result$.asObservable(), progress$.asObservable(), unresolved$.asObservable()];
  }

  public sealBlobs(html: string | null | undefined) {
    if (!html) return '';

    let result = html;

    for (const resolver of this.reversedResolvers) {
      result = resolver.sealBlobs(result);
    }

    return result;
  }

  public async bakeBlobs(html: string | null | undefined) {
    if (!html) return '';

    let result = html;

    for (const resolver of this.reversedResolvers) {
      result = await resolver.bakeBlobs(result);
    }

    return result;
  }
}
