import { IPaging } from '@rcg/standalone/models/paging.model';
import { GqlInput } from './gql-input.model';

export interface RcgListItem {
  id: number;
  [x: string]: unknown;
}

export interface IListState {
  data: RcgListItem[];
  loading: boolean;
  error: string | null;
  gqlInput: GqlInput | null;
  paging: IPaging | null;
  search: string | null;
  count: number | null;
}

export const initialListState: IListState = {
  data: [],
  loading: false,
  error: null,
  gqlInput: null,
  paging: null,
  search: '',
  count: null,
};
